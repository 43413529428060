import ApiService from "@/core/services/api.service";

var Supplier = {
    list: function(searchData) {
        return ApiService.post("/supplier/list", searchData);
    },
    save: function(supplier) {
        return ApiService.post("/supplier/save", supplier);
    },
    del: function(id) {
        return ApiService.get("/supplier/del?id=" + id);
    },
    findById: function(id) {
        return ApiService.get("/supplier/findById?id=" + id);
    }
}

export default Supplier;